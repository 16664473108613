
//import * as Vue from "vue";
import { Vue, Options }  from "vue-class-component";
@Options({
  components: {},
  
})
export default class Profile extends Vue {

  form =  false;
  dynHeight = 0;

  mounted() {
    setTimeout(() => {
      this.handleResize();
    }, 100);
    window.addEventListener('resize', this.handleResize);
  }

  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.dynHeight = document.getElementById('submitProfile').offsetHeight;
  }

  get page() {
    return this.$store.state.page;
  }

  openForm() {
    this.form = true;
    document.body.classList.add('no-scroll');

  }

  closeForm() {
    this.form = false;
    document.body.classList.remove('no-scroll');
  }
}
