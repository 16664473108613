
import { Options, Vue } from 'vue-class-component';

import { event } from 'vue-gtag';

@Options({
  computed: {
    CMS_URL () {
      return this.$store.getters.getCMSURL;
    },
    posts () {
      return this.$store.getters.getPosts;
    }
  }
})
export default class News extends Vue {

  CMS_URL;
  posts;
  activePost = 0;
  pages = [];
  slideIndex = 0;
  scrolled = false;

  created() {
    //console.log(this.CMS_URL);

    this.getPosts();
  }

  mounted() {
    let cookies = (this.$parent as any).$root.cookies,
      gdprCookie = cookies.get("gdprCookie"),
      id = this.$route.params.id;

    if(gdprCookie == 'true') {

      event(null, { 
        event: null, // Event type [default = 'interaction'] (Optional)
        category: 'News',
        action: 'click',
        label: 'News Views',
        value: 5000,
        noninteraction: false, // Optional
      });

    }

    window.addEventListener('scroll', this.handleScroll);

  }

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);

  }

  getPosts() {
    if(!this.posts) {
      this.axios.get(this.CMS_URL + 'mt7rk/posts').then((response) => {
        //console.log(response.data);
        this.$store.commit('setPosts', JSON.parse(response.data).posts);
        //console.log(this.posts);
        this.generatePages();
      });
    } else {
      this.generatePages();
    }
  }

  generatePages() {

    for(let i = 0; i < this.posts.news.length; i++) {
      if(!this.pages[Math.floor(i / 5)]) {
        this.pages[Math.floor(i / 5)] = [];
      }

      this.pages[Math.floor(i / 5)].push(this.posts.news[i]);

      /*if(i == this.posts.news.length-1) {
        console.log(this.pages);
      }*/
    }
  }

  setActivePost(index) {
    this.activePost = index;
    history.pushState(
        {},
        null,
        '/news/'+decodeURIComponent(this.posts.news[index].post_name)
    )
    this.$forceUpdate();

    setTimeout(() => {
      window.scrollTo(0, 0); // values are x,y-offset
    }, 10);

  }

  moveSlider(dir) {
    this.slideIndex += dir;
    this.activePost = 0;
  }

  handleScroll() {
    //console.log("sup");

    //let height = Math.max( document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);

    let room = window.innerHeight - 100 - document.getElementById("newsWrap").offsetHeight;
    //console.log(room);
    //console.log(((document as any).scrollingElement.clientHeight));

    let height = Math.max( document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
    height = height - window.innerHeight;
    
    //console.log("height: " +(height - (350 + room))+", scroll: "+document.scrollingElement.scrollTop)

    if((document.scrollingElement.scrollTop) > Math.abs(height - (350 + room))) {
      //console.log("good");
      this.scrolled = true;
    } else {
      this.scrolled = false;
    }

    //this.scrollPos = window.scrollY;
  }

}
