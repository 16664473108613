import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "view",
  id: "tagList"
}
const _hoisted_2 = { class: "top" }
const _hoisted_3 = { key: 0 }

export function render(_ctx, _cache) {
  const _component_Grid = _resolveComponent("Grid")
  const _component_Vue3Lottie = _resolveComponent("Vue3Lottie")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.currentTag)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.currentTag), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.filterStatus && _ctx.list && _ctx.list.length > 0)
      ? (_openBlock(), _createBlock(_component_Grid, {
          key: 0,
          posts: _ctx.list.slice(0,_ctx.numMore)
        }, null, 8, ["posts"]))
      : _createCommentVNode("", true),
    (_ctx.list && _ctx.numMore < _ctx.list.length)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: "",
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.loadMore && _ctx.loadMore(...args))),
          class: "loadlink red big1"
        }, [
          (_ctx.LoadJSON)
            ? (_openBlock(), _createBlock(_component_Vue3Lottie, {
                key: 0,
                ref: "loadCom",
                class: "loadMore",
                animationData: _ctx.LoadJSON,
                height: 115,
                width: 294,
                loop: true,
                autoPlay: false,
                onMouseover: _ctx.overAnim,
                onMouseout: _ctx.outAnim
              }, null, 8, ["animationData", "onMouseover", "onMouseout"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}